import Vue from 'vue'
import VueRouter from 'vue-router'

const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
  return originalReplace.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const pcRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/Home')
  },
  {
    path: '/newsdetails',
    name: 'NewsDetails',
    component: () => import('@/views/NewsDetails')
  },
  {
    path: '/newsdetailspage',
    name: 'NewsDetailsPage',
    component: () => import('@/views/NewsDetails/NewsDetailPage')
  },
  {
    path: '/career',
    name: 'CareerDetail',
    component: () => import('@/views/CareerDetail')
  },
  {
    path: "/about.html",
    name: "About",
    component: () => import("@/views/About")
  },
  {
    path: "/child.html",
    name: "Child",
    component: () => import("@/views/Floor/Child.vue")
  },
  {
    path: "/cooperation.html",
    name: "Cooperation",
    component: () => import("@/views/Floor/Cooperation.vue")
  },
  {
    path: "/contact.html",
    name: "Contact",
    component: () => import("@/views/Floor/Contact.vue")
  },
  {
    path: "/privacy.html",
    name: "Privacy",
    component: () => import("@/views/Floor/Privacy.vue")
  },
  {
    path: "/iosprivacy.html",
    name: "Privacy",
    component: () => import("@/views/Floor/IOSPrivacy.vue")
  },
  {
    path: "/protocol.html",
    name: "Protocol",
    component: () => import("@/views/Floor/Protocol.vue")
  },
  {
    path: "/guardianship.html",
    name: "Guardianship",
    component: () => import("@/views/Floor/Guardianship.vue")
  },
  {
    path: "/thirdpartysdk.html",
    name: "ThirdSDK",
    component: () => import("@/views/Floor/ThirdSDK.vue")
  },
  {
    path: "/gardenthirdpartysdk.html",
    name: "GardenThirdSDK",
    component: () => import("@/views/Floor/GardenThirdSDK.vue")
  },
  {
    path: "/gamesecurity.html",
    name: "GameSecurity",
    component: () => import("@/views/Floor/GameSecurity.vue")
  },

  {
    path: "/resistrumors.html",
    name: "ResistRumors",
    component: () => import("@/views/Floor/resistrumors.vue")
  }

]

const phoneRoutes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('@/mobileViews/home')
  },
  {
    path: '/career',
    name: 'Recruit',
    component: () => import('@/mobileViews/Recruit')
  },
  {
    path: '/about.html',
    name: 'AboutUs',
    component: () => import('@/mobileViews/AboutUs')
  },
  {
    path: '/newsdetails',
    name: 'NewIndex',
    component: () => import('@/mobileViews/NewIndex')
  },
  {
    path: '/newsdetailspage',
    name: 'NewContent',
    component: () => import('@/mobileViews/NewContent')
  },
  {
    path: '/cooperation',
    name: 'Cooperation',
    component: () => import('@/mobileViews/footPage/Cooperation')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/mobileViews/footPage/Contact')
  },
  {
    path: '/protocol.html',
    name: 'Protocol',
    component: () => import('@/mobileViews/footPage/protocol')
  },
  {
    path: '/privacy.html',
    name: 'Privacy',
    component: () => import('@/mobileViews/footPage/privacy')
  },
  {
    path: '/iosprivacy.html',
    name: 'Privacy',
    component: () => import('@/mobileViews/footPage/iosPrivacy')
  },
  {
    path: '/child.html',
    name: 'Child',
    component: () => import('@/mobileViews/footPage/child')
  },
  {
    path: '/guardianship.html',
    name: 'Guardianship',
    component: () => import('@/mobileViews/footPage/guardianship')
  },
  {
    path: '/thirdpartysdk.html',
    name: 'Thirdsdk',
    component: () => import('@/mobileViews/footPage/thirdsdk')
  },
  {
    path: '/gardenthirdpartysdk.html',
    name: 'GardenThirdsdk',
    component: () => import('@/mobileViews/footPage/gardenthirdpartysdk')
  },
  {
    path: '/gamesecurity.html',
    name: 'Gamesecurity',
    component: () => import('@/mobileViews/footPage/gamesecurity')
  },
  {
    path: "/resistrumors.html",
    name: "ResistRumors",
    component: () => import("@/mobileViews/footPage/resistrumors.vue")
  }
]

let routes = []

if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  routes = phoneRoutes
} else {
  routes = pcRoutes
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
