<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    window.onload = function() {
    // 阻止双击放大行为
      var lastTouchEnd = 0;
      document.addEventListener('touchstart', function(event) {
        if (event.touches.length > 1) {
          event.preventDefault();
        }
      });
      document.addEventListener('touchend', function(event) {
        var now = (new Date()).getTime();
        if (now - lastTouchEnd <= 300) {
          event.preventDefault();
        }
        lastTouchEnd = now;
      }, false);

      // 阻止双指进行缩放大行为
      document.addEventListener('gesturestart', function(event) {
        event.preventDefault();
      });
    }
  }
};
</script>
<style lang="scss">
// 首页黑白滤镜
// body{
//   filter: grayscale(1);
// }
#app {
  font-family: Microsoft YaHei, Avenir, Helvetica, Arial, sans-serif, Poppins;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
