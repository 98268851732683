import Vue from 'vue'
import App from './App.vue'
import router from './router'
// import Vant from "vant";
// 全局引入按需引入UI库 vant
import '@/plugins/vant'

import "swiper/css/swiper.min.css"

import "@/utils/format"
import "@/utils/rem"
import "@/styles/index.css"
Vue.config.productionTip = false
// Vue.use(Vant);
// Vue.use(SwipeItem);
new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
